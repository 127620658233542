import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql , Link} from "gatsby"

const Tiemporecord = () => {
    const datarecord = useStaticQuery(graphql`
    {
      allStrapiArticle(
        sort: {order: DESC, fields: published_at}
        limit: 4
        filter: {home_cocina_en_tiempo_record: {eq: true}}
      ) {
        edges {
          node {
            id
            title
            slug
            Foto_Thermomix {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 7, layout: CONSTRAINED, placeholder: NONE)
                }
              }
            }
          }
        }
      }
    }
  `)
      return (  
      <div className="bg-white">
          <div className="mx-auto pt-8 pb-4">
          <div className="grid grid-cols-1">
              <ul role="list" className="sm:grid sm:grid-cols-4 list-none gap-4">
                  {datarecord.allStrapiArticle.edges.map((record) => (
                  <li key={record.node.id}>
                    <Link to={`/recetas/${record.node.slug}`}>
                      <div className="items-center relative ">
                      <GatsbyImage
                          alt={`${record.node.titulo}`}
                          image={
                            record.node.Foto_Thermomix.localFile.childImageSharp
                          .gatsbyImageData
                          }
                          className="w-full h-full "
                      />
                      <div className="w-full h-full flex flex-col absolute bottom-0 left-1/2 transform -translate-x-1/2 justify-end text-center text-lg bg-gradient-to-t from-negrotransparente">
                          <h3 className="text-2xl sm:text-26 leading-7 px-6 md:px-6 archebook text-center text-white font-medium pb-4">{record.node.title}</h3>
                      </div>
                      </div>
                    </Link>
                  </li>
                  ))}
              </ul>
          </div>
          </div>
      </div>
  )
        
}     

    export default Tiemporecord;