import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql , Link} from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/bundle";

import SwiperCore, {
  Autoplay,Pagination,Navigation
} from 'swiper';

SwiperCore.use([Autoplay,Pagination,Navigation]);

const Swiperreel = () => {
    const dataswiperreel = useStaticQuery(graphql`
    {
        allStrapiReels (
            sort: {order: DESC, fields: published_at}
            limit: 10
        ){
            edges {
                node {
                    id
                    slug
                    titulo
                    tiemporeceta
                    published_at
                    badge
                    poster {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                            }
                        }
                    }
                    meta
                }
            }
        }
    }
    `)
    return (  
    <Swiper slidesPerView={1} spaceBetween={0} breakpoints={{
        "0": {
            "slidesPerView": 1.5,
            "spaceBetween": 8
        },
        "640": {
            "slidesPerView": 2.5,
            "spaceBetween": 8
        },
        "768": {
            "slidesPerView": 4.5,
            "spaceBetween": 8
        },
        "1024": {
            "slidesPerView": 5.5,
            "spaceBetween": 8
        },
        "1600": {
            "slidesPerView": 6.5,
            "spaceBetween": 8
        }
        }} navigation={true} modules={[Pagination]}  className="mySwiper">
            {dataswiperreel.allStrapiReels.edges.map((slidereel) => (
                <SwiperSlide key={slidereel.node.id}>
                       <div className="object-cover h-full w-full relative">
                       <Link to={`/reels/${slidereel.node.slug}`}>
                          <GatsbyImage
                            alt={`${slidereel.node.titulo}`}
                            image={
                                slidereel.node.poster.localFile.childImageSharp
                            .gatsbyImageData
                            }
                            className="h-full w-full object-cover aspect-w-9 aspect-h-16 z-0"
                          />
                          {slidereel.node.badge &&
                            <div className="absolute top-0 text-sm px-3 bg-white text-black rounded">{slidereel.node.badge}</div>
                          }
                          <div className="absolute left-1/2 transform -translate-x-1/2 justify-end  top-1/2 -translate-y-1/2">
                            <img src="https://recetascocinasana.s3.eu-west-1.amazonaws.com/iconoplay_a40a72c71a.png" alt="play" className="w-20 z-10" />
                          </div>
                          <div className="opacity-100 hover:opacity-100 md:opacity-0 duration-0 md:duration-500 z-10">
                              <div className="w-full md:h-full flex flex-col absolute top-3/4 md:top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 justify-center items-center  text-center text-lg">
                              <span className="text-gristexto font-bold leading-5 bg-amarillotransparente w-10/12 sm:w-8/12 p-4 rounded ">
                                    {slidereel.node.titulo}
                              </span>
                              </div>
                          </div>
                          </Link>
                        </div>
                </SwiperSlide>
            ))}   

        </Swiper>
    )
        
}     

    export default Swiperreel;