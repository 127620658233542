import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql , Link} from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/bundle";


import SwiperCore, {
  Autoplay,Pagination,Navigation
} from 'swiper';

SwiperCore.use([Autoplay,Pagination,Navigation]);

const swiper = () => {
    const dataswiper = useStaticQuery(graphql`
    {
      allStrapiArticle(limit: 9, sort: {order: DESC, fields: published_at}) {
        edges {
          node {
            id
            title
            slug
            Seo {
              shareImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
    return (  
    <Swiper slidesPerView={1} spaceBetween={0} breakpoints={{
        "0": {
          "slidesPerView": 1.2,
          "spaceBetween": 8
        },
        "640": {
            "slidesPerView": 2.5,
            "spaceBetween": 8
        },
        "768": {
            "slidesPerView": 3.5,
            "spaceBetween": 8
        },
        "1024": {
            "slidesPerView": 3.5,
            "spaceBetween": 8
        },
        "1600": {
            "slidesPerView": 3.5,
            "spaceBetween": 8
        }
        }} navigation={true} modules={[Pagination]} className="mySwiper">
            {dataswiper.allStrapiArticle.edges.map((slide) => (
                <SwiperSlide key={slide.node.id}>
                       <div className="object-cover h-full w-full relative">
                       <Link to={`/recetas/${slide.node.slug}`}>
                          <GatsbyImage
                            alt={`${slide.node.title}`}
                            image={
                                slide.node.Seo.shareImage.localFile.childImageSharp
                            .gatsbyImageData
                            }
                            className="h-full w-full object-cover aspect-w-6 aspect-h-6 z-0"
                          />
                          <div className="opacity-100 md:opacity-0 md:hover:opacity-100 md:duration-500 z-10">
                              <div className="w-full h-full flex flex-col absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 justify-center items-center  text-center text-lg">
                              <span className="text-gristexto font-bold leading-5 bg-amarillotransparente w-8/12 p-4 rounded ">
                                    {slide.node.title}
                              </span>
                              </div>
                          </div>
                          </Link>
                        </div>
                </SwiperSlide>
            ))}   

        </Swiper>
    )
        
}     

    export default swiper;