import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql , Link} from "gatsby"

const Trucoshome = () => {
  const datatrucos = useStaticQuery(graphql`
    {
      allStrapiNoticiasConsejosTrucos(
        filter: {categoria_noticia: {nombre: {eq: "Trucos de cocina"}}}
        limit: 4
        sort: {order: DESC, fields: createdAt}
      ) {
        edges {
          node {
            id
            seo {
              shareImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE, quality: 7, sizes: "1080")
                  }
                }
              }
            }
            slug
            strapiId
            subtitulo_home
            titulo
          }
        }
      }
    }
  `)
      return (  
      <div className="bg-white pb-0">
          <div className="mx-auto py-2 px-0 max-w-5xl sm:px-0 lg:px-0">
          <div className="grid grid-cols-1">
              <ul role="list" className="sm:grid sm:grid-cols-2 list-none gap-10">
                  {datatrucos.allStrapiNoticiasConsejosTrucos.edges.map((noticia) => (
                  <li key={noticia.node.strapiId}>
                    <Link to={`/trucos-de-cocina/${noticia.node.slug}`}>
                      <div className="items-center ">
                      <GatsbyImage
                          alt={`${noticia.node.titulo}`}
                          image={
                            noticia.node.seo.shareImage.localFile.childImageSharp
                          .gatsbyImageData
                          }
                          className="w-full h-full"
                      />
                      <div className="ml-3">
                          <p className="uppercase leading-5 text-lg text-verde mt-6 pb-2 text-center font-bold">{noticia.node.subtitulo_home}</p>
                          <h3 className="text-26 leading-7 px-6 md:px-14 archersemibold text-center text-black font-medium pb-4 md:pb-0">{noticia.node.titulo}</h3>
                      </div>
                      </div>
                    </Link>
                  </li>
                  ))}
              </ul>
          </div>
          </div>
      </div>
  )
        
}     

    export default Trucoshome;
