import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql , Link} from "gatsby"

const Meriendas = () => {
    const datameriendas = useStaticQuery(graphql`
    {
      allStrapiArticle(
        sort: {order: DESC, fields: published_at}
        limit: 4
        filter: {home_muchos_padres_quieren: {eq: true}}
      ) {
        edges {
          node {
            id
            title
            slug
            Seo {
              shareImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE, quality: 7)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
      return (  
      <div className="">
          <div className="mx-auto py-4">
          <div className="grid grid-cols-1">
              <ul role="list" className="sm:grid sm:grid-cols-4 list-none gap-4">
                  {datameriendas.allStrapiArticle.edges.map((merienda) => (
                  <li key={merienda.node.id} className="object-cover w-full relative" >
                  <Link to={`/recetas/${merienda.node.slug}`}>
                     <GatsbyImage
                       alt={`${merienda.node.title}`}
                       image={
                        merienda.node.Seo.shareImage.localFile.childImageSharp
                        .gatsbyImageData
                       }
                       className="w-full object-cover aspect-w-6  aspect-h-6 rounded-full"
                     />
                     <div className="opacity-100 md:opacity-0 hover:opacity-100 duration-500">
                         <div className="w-full flex flex-col absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 justify-center items-center  text-center text-lg">
                         <span className="text-gristexto font-bold leading-5 bg-amarillotransparente w-8/12 p-4 rounded ">
                               {merienda.node.title}
                         </span>
                         </div>
                     </div>
                     </Link>
                   </li>
                  ))}
              </ul>
          </div>
          </div>
      </div>
  )
        
}     

    export default Meriendas;