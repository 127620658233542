import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql , Link} from "gatsby"

const Consejoshome = () => {
  const dataconsejos = useStaticQuery(graphql`
    {
      allStrapiNoticiasConsejosTrucos(
        filter: {categoria_noticia: {nombre: {eq: "Consejos de salud"}}}
        limit: 6
        sort: {order: DESC, fields: createdAt}
      ) {
        edges {
          node {
            id
            seo {
              shareImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE, quality: 7, sizes: "1080")
                  }
                }
              }
            }
            slug
            strapiId
            subtitulo_home
            titulo
          }
        }
      }
    }
  `)
      return (  
      <div className="bg-white">
          <div className="max-w-5xl mx-auto px-4 sm:px-4 lg:px-8 mb-6">
            <h2 className="poppins text-center text-3xl lg:text-4xl leading-9 pb-1 max-w-xl ml-4">Consejos de salud para cuidarte mejor</h2>
            <Link to={`/consejos-de-salud/`}>
              <div className="text-center">
              <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium font-poppins rounded-md shadow-sm text-white bg-verde hover:bg-moradoclaro focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-morado mx-2">MÁS SOBRE CONSEJOS DE SALUD
              </button>
              </div>
            </Link>
          </div>
          <div className="mx-auto py-2 px-0 max-w-5xl sm:px-0 lg:px-0">
          <div className="grid grid-cols-1">
              <ul role="list" className="sm:grid sm:grid-cols-2 list-none gap-10">
                  {dataconsejos.allStrapiNoticiasConsejosTrucos.edges.map((consejo) => (
                  <li key={consejo.node.strapiId}>
                    <Link to={`/consejos-de-salud/${consejo.node.slug}`}>
                      <div className="items-center ">
                      <GatsbyImage
                          alt={`${consejo.node.titulo}`}
                          image={
                            consejo.node.seo.shareImage.localFile.childImageSharp
                          .gatsbyImageData
                          }
                          className="w-full h-full object-cover aspect-w-3 aspect-h-2"
                      />
                      <div className="ml-3">
                          <p className="uppercase leading-5 text-lg text-verde mt-6 pb-2 text-center font-bold">{consejo.node.subtitulo_home}</p>
                          <h3 className="text-26 leading-7 px-6 md:px-6 archersemibold text-center text-black font-medium pb-8 md:pb-0">{consejo.node.titulo}</h3>
                      </div>
                      </div>
                    </Link>
                  </li>
                  ))}
              </ul>
          </div>
          </div>
      </div>
  )
        
}     

    export default Consejoshome;
