import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql , Link} from "gatsby"

const Noticiashome = () => {
  const datanoticias = useStaticQuery(graphql`
    {
      allStrapiNoticiasConsejosTrucos(
        filter: {categoria_noticia: {nombre: {eq: "Noticias"}}}
        limit: 4
        sort: {order: DESC, fields: createdAt}
      ) {
        edges {
          node {
            id
            seo {
              shareImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE, quality: 7, sizes: "1080")
                  }
                }
              }
            }
            slug
            strapiId
            subtitulo_home
            titulo
          }
        }
      }
    }
  `)
      return (  
      <div className=" pb-6">
          <div className="mx-auto py-2 px-0 max-w-5xl sm:px-0 lg:px-0">
          <div className="grid grid-cols-1">
              <ul role="list" className="sm:grid sm:grid-cols-1 list-none gap-10 md:gap-4 mb-16">
                  {datanoticias.allStrapiNoticiasConsejosTrucos.edges.map((news) => (
                  <li key={news.node.strapiId}>
                    <Link to={`/cocina-sana/${news.node.slug}`}>
                      <div className="items-center px-6">
                      <GatsbyImage
                          alt={`${news.node.titulo}`}
                          image={
                            news.node.seo.shareImage.localFile.childImageSharp
                          .gatsbyImageData
                          }
                          className="w-full h-full"
                      />
                      <div className="ml-3">  
                          <h3 className="text-2xl leading-7 pt-4 pb-0 font-medium text-center text-black">{news.node.titulo}</h3>
                          <p className="leading-5 text-base  mt-4 pb-6 text-center font-regular md:pb-2 md:mt-2">{news.node.subtitulo_home}</p>
                      </div>
                      </div>
                    </Link>
                  </li>
                  ))}
              </ul>
          </div>
          </div>
      </div>
  )
        
}     

    export default Noticiashome;
