import React from "react";
import { Link } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/bundle";

import SwiperCore, {
  Autoplay, Pagination, Navigation
} from 'swiper';

SwiperCore.use([Autoplay, Pagination, Navigation]);

const Swipermehaspodidover = () => {
    // Reemplaza 'imageUrl' con tus propias URLs de imágenes
    const images = [
        {
            id: '1',
            title: 'El Español',
            imageUrl: 'https://recetascocinasana.s3.eu-west-1.amazonaws.com/El_Espanol_logo_56e79604e2.jpg',
            slug: '/link-to-page-1',
        },
        {
            id: '2',
            title: 'Esi',
            imageUrl: 'https://recetascocinasana.s3.eu-west-1.amazonaws.com/logo_esi_f6904eda3e.png',
            slug: '/link-to-page-1',
        },
        {
            id: '3',
            title: 'Saber vivir',
            imageUrl: 'https://recetascocinasana.s3.eu-west-1.amazonaws.com/sabervivir_a2ab4af9e7.png',
            slug: '/link-to-page-1',
        },
        {
            id: '4',
            title: 'Welife',
            imageUrl: 'https://recetascocinasana.s3.eu-west-1.amazonaws.com/welife_logo_fdacffaca5.png',
            slug: '/link-to-page-1',
        },
        {
            id: '5',
            title: 'Maestros del deporte',
            imageUrl: 'https://recetascocinasana.s3.eu-west-1.amazonaws.com/Maestros_del_deporte_7301dce67b.jpeg',
            slug: '/link-to-page-1',
        },
        {
            id: '6',
            title: 'Instyle',
            imageUrl: 'https://recetascocinasana.s3.eu-west-1.amazonaws.com/logo_vector_instyle_70b902bfca.jpg',
            slug: '/link-to-page-1',
        },
        {
            id: '7',
            title: 'Club stop diabetes',
            imageUrl: 'https://recetascocinasana.s3.eu-west-1.amazonaws.com/PHOTO_2024_01_15_14_05_51_2_cb8ec702b8.jpg',
            slug: '/link-to-page-1',
        },
        // ... más imágenes
    ];

    return (  
        <Swiper 
            slidesPerView={1} 
            spaceBetween={0} 
            breakpoints={{
                "0": {"slidesPerView": 3.5, "spaceBetween": 8},
                "640": {"slidesPerView": 4.5, "spaceBetween": 8},
                "768": {"slidesPerView": 4.5, "spaceBetween": 8},
                "1024": {"slidesPerView": 5.5, "spaceBetween": 8},
                "1600": {"slidesPerView": 7, "spaceBetween": 8}
            }} 
            navigation={true} 
            modules={[Pagination, Navigation]} 
            className="mySwiper"
        >
            {images.map((image) => (
                <SwiperSlide key={image.id} className="flex justify-center items-center" style={{ height: 'auto' }}>
                    <div className="text-center">
                        <img
                            src={image.imageUrl}
                            alt={image.title}
                            className="mx-auto object-contain"
                            style={{ maxHeight: '80vh' }} // Ajusta esto según tus necesidades
                        />
                        {/* <div className="mt-2">
                            <span className="text-gray-700 font-bold bg-yellow-200 p-4 rounded">
                                {image.title}
                            </span>
                        </div> */}
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}    

export default Swipermehaspodidover;
