import React, {useEffect } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Layout from "../components/layout";
import Swiper from "../components/swiper";
import { GatsbyImage } from "gatsby-plugin-image";
import Trucoshome from "../components/trucoshome";
import Consejoshome from "../components/consejoshome";
import Noticiashome from "../components/noticiashome";
import Tiemporecord from "../components/cocinatiemporecord";
import Meriendas from "../components/seccionhomemeriendas";
import Markdown from "react-markdown";
import Newsletter from "../components/newsletter";
import LogoblancoImg from "../images/newlogoclub.svg";
import Swiperreel from "../components/reelshomeswiper";
import Swipermehaspodidover from "../components/swipermehaspodidover";

// import GoogleAds from "../components/googleads";

import "../assets/css/main.css";

const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;


export default function IndexPage() {
  
  const data = useStaticQuery(query);
  const rrss = {
    social: [
      {
        name: 'email',
        href: 'mailto:paola@lacocinasana.com',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: 'Facebook',
        href: 'https://www.facebook.com/lacocinasanaoficial',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: 'Instagram',
        href: 'https://www.instagram.com/paola_cocinasana/',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: 'Pinterest',
        href: 'https://www.pinterest.es/lacocinasanaoficial',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.017 0C5.396 0 .029 5.367.029 11.987c0 5.079 3.158 9.417 7.618 11.162-.105-.949-.199-2.403.041-3.439.219-.937 1.406-5.957 1.406-5.957s-.359-.72-.359-1.781c0-1.663.967-2.911 2.168-2.911 1.024 0 1.518.769 1.518 1.688 0 1.029-.653 2.567-.992 3.992-.285 1.193.6 2.165 1.775 2.165 2.128 0 3.768-2.245 3.768-5.487 0-2.861-2.063-4.869-5.008-4.869-3.41 0-5.409 2.562-5.409 5.199 0 1.033.394 2.143.889 2.741.099.12.112.225.085.345-.09.375-.293 1.199-.334 1.363-.053.225-.172.271-.401.165-1.495-.69-2.433-2.878-2.433-4.646 0-3.776 2.748-7.252 7.92-7.252 4.158 0 7.392 2.967 7.392 6.923 0 4.135-2.607 7.462-6.233 7.462-1.214 0-2.354-.629-2.758-1.379l-.749 2.848c-.269 1.045-1.004 2.352-1.498 3.146 1.123.345 2.306.535 3.55.535 6.607 0 11.985-5.365 11.985-11.987C23.97 5.39 18.592.026 11.985.026L12.017 0z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
    ],
  }

  return (
    <Layout seo={data.strapiHomepage.seo}>

      <div className="py-4 mb-4">

              <div className=" visible md:hidden
               circularmedium text-left text-3xl lg:text-3xl leading-9 px-4 md:px-0 ">Nutrición antiinflamatoria con Paola Procell</div>
              <div className="grid grid-cols-1 gap-0 md:gap-10 md:grid-cols-3 max-w-5xl mx-auto ">
                  <div className="md:py-2 mb-0 md:col-span-2">
                    <img
                        alt="comida sana"
                        src="https://recetascocinasana.s3.eu-west-1.amazonaws.com/paola_cocinasana_antiinflamatoria_sana_7f211da85b.jpg"
                        className="w-full p-4 md:p-0 cover"
                    /> 
                     <div className="grid grid-cols-4 md:grid-cols-4 gap-0 text-center pt-4 mx-auto px-4 mb-8 w-80">
                {rrss.social.map((item) => (
                  <a key={item.name} href={item.href} className="flex flex-col items-center text-black hover:text-textogris text-xs" target="_blank" rel="noopener noreferrer">
                    <span>{item.name}</span>
                    <item.icon className="h-6 w-6 mt-2" aria-hidden="true" />
                  </a>
                ))}
              </div>  
                  </div>
                  <div className="py-0 mb-2 md:col-span-1">
                    <div class="hidden md:block circularmedium text-left text-3xl lg:text-3xl md:leading-9 pb-0 md:pb-6 px-4 md:px-0">
                        Nutrición antiinflamatoria con Paola Procell
                    </div>
                    <div>
                      <p className="pb-4 font-light pl-4 pr-4 lg:pl-0 lg:pr-0">Mi nombre es Paola, soy mexicana viviendo en Madrid desde hace más de 20 años. Desde 2016 ayudo a personas a <span className="font-bold">cuidarse desde la nutrición</span> cambiando la mentalidad de relacionarnos con la comida con un enfoque integral.&nbsp; Con mi Método Cocina Sana muchas personas se desinflaman, adelgazan, ganan energía,<span class="Apple-converted-space">&nbsp; </span>revierten resistencia a la insulina, hacen más ejercicio… Y todo eso se convierte en resultados que nos hacen sentir muy bien, a ellos y a mí.</p>
                      <p className="pb-4 font-light pl-4 pr-4 lg:pl-0 lg:pr-0">Además soy madre de dos niñas, y desde mi propia experiencia a veces siento que incluso aporto más que por los propios conocimientos de salud digestiva y nutrición. A mi hija mayor le costó mucho empezar a probar texturas y sabores, y la pequeña fue multialérgica no mediada y no toleró casi ningún alimento hasta los 5 años. <span className="font-bold">Sé lo que es lidiar en casa con ser la nutricionista de la familia además de cuidarte tú mismo.</span></p>
                      <p className="pb-4 font-light pl-4 pr-4 lg:pl-0 lg:pr-0">Cocinar es un acto de amor hacia nosotros mismos y para los demás, es el principio de empezar una vida antiinflamatoria.</p>
                      <p className="pb-4 font-bold pl-4 pr-4 lg:pl-0 lg:pr-0"><span>Tu salud empieza en la cocina.</span></p></div>
                      <div className="text-left">
                        <a href="https://lacocinasana.com/clubcocinasana/tienda-de-la-cocina-sana/" target="_blank">
                        <button
                            type="button"
                            className=" text-center px-4 py-2 border border-transparent text-lg font-medium font-poppins rounded-md shadow-sm text-black hover:text-white bg-amarillo hover:bg-moradoclaro focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-morado lg:mx-0 mx-4 w-80 mx-auto md:w-full font-bold">MIS PROGRAMAS
                        </button>
                        </a>
                      </div>
                  </div>
              </div>
              <div className="max-w-5xl mx-auto mt-8">
                <div className="circularmedium text-center text-2xl lg:text-3xl leading-9 pb-2 px-4 md:px-0 border-t-4 border-amarillolineas pt-8">Quizás me has podido ver</div>      
                <Swipermehaspodidover/>
                <div className="border-amarillolineas pt-8 border-t-4"></div>
              </div>
            </div>



      <div className="max-w-5xl mx-auto text-center px-4 sm:px-6 lg:px-8 ">
        <h1 className="max-w-3xl m-auto poppins pt-0 md:text-s38 text-4xl leading-11 pb-2 font-bold">Alimentación antiinflamatoria con recetas fáciles y saludables</h1>
        <h3 className="text-center archebook text-2xl m-auto pb-12 max-w-2xl leading-7">Pierde peso y gana salud metabólica con comida sana</h3>
      </div>
     <Swiperreel/>
  

      {/* <div className="bg-azulceleste bg-center bg-cover mb-10 max-w-5xl mx-auto">
        <div className="grid grid-cols-1 gap-0 md:gap-10 md:grid-cols-3 max-w-5xl mx-auto text-center">
            <div className="pt-6 md:col-span-1 order-last md:order-first">
              <img
                  alt="club cocina sana"
                  src="https://recetascocinasana.s3.eu-west-1.amazonaws.com/adelgaza_de_forma_efectiva_dieta_sana_ffc5d99e2b.jpeg"
                  className="w-4/5 mx-auto md:w-full  ml-8"
              />   
            </div>
            <div className="py-0 md:py-10 mb-2 md:col-span-2">
            <img
                  alt="logo club cocina sana"
                  src={LogoblancoImg}
                  className="w-32 pl-6 pr-6 pb-4 md:pb-6 pt-2 mx-auto"
              /> 
              <div className="poppins font-bold text-morado text-lg w-4/5 mx-auto leading-5 mb-2">Eres una fuente de inspiración saludable</div>
              <div className="poppins font-bold text-40 leading-10 mb-4 text-white px-8">La comunidad que cocina, come y adelgaza</div>
              <div className="poppins font-medium text-20 leading-6 max-w-xl mx-auto">Aprende a bajar de peso sin dietas con la comida sana.</div>
              <div className="text-center mt-6">
                <a href="https://lacocinasana.com/clubcocinasana/" target="_blank">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium poppins rounded-md shadow-sm text-white bg-morado hover:bg-moradoclaro focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-morado mx-2">QUIERO UN PESO SALUDABLE
                </button>
                </a>
                <Link to={`/consejos-de-salud/que-es-el-club-cocina-sana`} >
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium poppins rounded-md shadow-sm text-white bg-morado hover:bg-moradoclaro focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-morado mx-2 mt-6 sm:mt-0">QUIERO SABER DE QUÉ VA
                </button>
                </Link>
              </div>

            </div>
        </div>
      </div> */}
      {/* <div className="mb-10 max-w-5xl mx-auto gap-x-6 sm:grid-cols-2 grid divide-x divide-moradoclaro">
          <div className="hover:opacity-75">
            <a href="https://lacocinasana.com/clubcocinasana/tienda-de-la-cocina-sana/" target="_blank">
                <img
                    alt="bajar de peso"
                    src="https://recetascocinasana.s3.eu-west-1.amazonaws.com/hidratacion_fe31a8d7cd.jpg"
                    className="mx-auto w-full"
                />  
            </a>
          </div>
          <div className="hover:opacity-75 pl-6">
            <a href="https://lacocinasana.com/clubcocinasana/tienda-de-la-cocina-sana/" target="_blank">
                <img
                    alt="meriendas saludables"
                    src="https://recetascocinasana.s3.eu-west-1.amazonaws.com/libro_meriendas_9cf56bde7a.jpg"
                    className="mx-auto w-full"
                />  
            </a>
          </div>
      </div> */}
      <div className="grid grid-cols-1 gap-4 md:gap-10 md:grid-cols-3 max-w-5xl mx-auto text-center sm:px-0 lg:px-0 ">
        <div className="bg-white pt-0 mb-2 md:col-span-2 mt-8">
          <Consejoshome/>
          {/* <div className="ad-class">
              <GoogleAds slot="7037476756" />
          </div> */}
          {/* <a href="https://lacocinasana.com/clubcocinasana/batchcooking-saludable/" target="_blank" rel="noopener">
          <img
                alt="batch cooking"
                src="https://recetascocinasana.s3.eu-west-1.amazonaws.com/ANUNCIO_WEB_BATCHCOOKINGMOVILES_e98c1afc0b.jpg"
                className="w-full rounded px-4 sm:px-0 lg:px-0 mx-auto pt-4 cover"
            />  
          </a> */}
        </div>
        <div className="bg-noticias-home bg-center bg-cover py-6 mb-2 col-span-1 mt-8">
            <div className="text-center mt-6">
            <Link to={`/cocina-sana/`}>
              <div className="font-bold text-2xl mb-4 leading-5 mx-6 bg-amarillo hover:bg-amarillotransparente px-4 py-4 tracking-wide rounded-md shadow-sm">
                <div className="pb-2 poppins">NOTICIAS</div>
                <div className="font-light poppins text-s22">DE LA COCINA SANA</div>
              </div>
            </Link>
            <Noticiashome/>
            </div>

        </div>
      </div>
      <div className="max-w-5xl mx-auto px-4 sm:px-0 border-t-4 border-amarillolineas mt-8 mb-4">
        <h2 className="poppins text-center text-3xl lg:text-4xl leading-9 pb-1">Recetas</h2>
        <Swiper/>
      </div>

      <div className="bg-white py-6 mb-2 ">
      <div className="max-w-5xl mx-auto px-4 sm:px-4 lg:px-8 border-t-4 border-amarillolineas">
        <h2 className="poppins text-center text-3xl lg:text-4xl leading-9 pb-1">Trucos de cocina saludable</h2>
        <Link to={`/trucos-de-cocina/`}>
          <div className="text-center">
          <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium poppins rounded-md shadow-sm text-black hover:text-white bg-amarillo hover:bg-moradoclaro focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-morado mx-2">APRENDE A NUTRIRTE MEJOR
          </button>
          </div>
        </Link>
      </div>
      </div>
      <Trucoshome/>
      <div className="bg-white pt-6 mb-4 ">
        <div className="max-w-5xl mx-auto px-4 sm:px-0 lg:px-0 border-t-4 border-amarillolineas mt-8">
          <h2 className="poppins text-center text-3xl lg:text-4xl leading-9 pb-1">Cocina en tiempo récord</h2>
          <div className="archebook text-center text-s22 leading-6 max-w-2xl mx-auto">Thermomix: tu gran aliada para ahorrar tiempo y esfuerzo.</div>
            <Tiemporecord/>
        </div>
      </div>
      
      <div className="bg-amarillo py-6 mb-10">
      <div className="max-w-5xl mx-auto px-4 sm:px-4 lg:max-w-5xl lg:px-8">
        <h2 className="poppins text-center text-3xl lg:text-4xl leading-9 pb-1">Muchos padres quieren preparar en casa...</h2>
        <h3 className="text-center font-regular text-xl m-auto pb-4 leading-5 archebook">Meriendas saludables para lograr que los niños coman mejor con alimentos sanos</h3>
        <Meriendas/>
      </div>
      </div>

      {/* <div className="py-4 mb-10">
        <div className="grid grid-cols-1 gap-4 md:gap-10 md:grid-cols-2 max-w-5xl mx-auto ">
            <div className="py-2 mb-0 md:col-span-1">
              <img
                  alt="comida sana"
                  src="https://recetascocinasana.s3.eu-west-1.amazonaws.com/Lc_copyright_c3556d52_5f858348b3.jpeg"
                  className="w-full p-4 md:p-0 cover"
              />   
            </div>
            <div className="py-0 mb-2 md:col-span-1">
              <div className="poppins text-left text-3xl lg:text-4xl leading-9 pb-6 px-4 md:px-0">¿Quiénes somos?</div>
              <div className="archebook text-20 leading-7 text-negrotexto px-4 md:px-0">
              <p>Hola, soy Paola Procell y esta es mi familia: Daniela, Manuel y Paola. <span className="archersemibold">Empecé este proyecto con ellos alrededor de la cocina</span>. Mi marido es el programador y el que se come las sobras de la comida. Empezamos La Cocina Sana con la idea de ser un recetario online para nuestras hijas y se convirtió en la web que ves ahora y esperamos que siga creciendo.</p>
              <p className="pt-4">¿Quieres saber mi historia la cuál me llevó a estudiar Técnico Superior en Dietética? ¿Cómo una diseñadora gráfica terminó cocinando sano y en el mundo de la salud?</p>
              <p className="text-xs pt-2 text-black archersemibold"><a href="/cocina-sana/la-cocina-sana-quienes-somos">SIGUE LEYENDO...</a></p>
              </div>
            </div>
        </div>
        <div className="md:w-1/3 grid-cols-3 grid md:grid-cols-5 gap-4  text-center pt-8 mx-auto px-4">
          {rrss.social.map((item) => (
            <a key={item.name} href={item.href} className="text-black hover:text-textogris text-center" target="_blank">
              <span className="text-center">{item.name}</span>
              <item.icon className="h-6 w-6 mt-2 ml-11" aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="px-4 md:px-0 text-center circularbook mt-8 max-w-2xl leading-6 mx-auto text-black text-20">¿Tu marca es saludable? La Cocina Sana ® también es un espacio para potenciar todo lo relacionado con la salud y bienestar.</div>
      </div> */}
      {/* {data.allStrapiTextosGenerales.edges.map((texto) => ( */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-0 mt-8 mb-8 max-w-5xl leading-6 mx-auto text-xl md:text-lg archebook text-negrotexto px-4 md:px-0 md:divide-solid md:divide-x-2 divide-amarillolineas">
            <div className="md:px-4 py-4 md:border-l-2 md:border-amarillolineas">
            💛 La web de <span className="circularbook">La Cocina Sana ®</span> nació en internet en 2001 como un recetario de la abuela con comida saludable. Desde 2018 es un portal educativo para conseguir una alimentación saludable y sostenible en el tiempo.
            </div>
            <div className="md:px-4 md:py-4">
            ✨Soy Paola Procell, Técnico en Superior en Dietética y Coach Nutricional, pero sobretodo soy una mujer que me preocupo por mi salud y la de mi familia. <span className="circularbook">Me gustaría ayudarte a encontrar tu equilibrio físico-mental</span> para que encuentres la armonía con tu cuerpo y tu salud. 
            </div>
            <div className="md:px-4 py-4">
            🔥Aquí tienes <span className="circularbook">recetas fáciles y saludables con trucos y consejos para cocinar más sano</span>. Tan simples que puedes repetir las veces que quieras.  En mi vida diaria me han funcionado muy bien con mis hijas, mi madre y conmigo misma. Quiero que disfrutes cocinando y comiendo riquísimo y siendo feliz.
            </div>
            <div className="md:px-4 py-4">
            🧬 <span className="circularbook">La Cocina Sana ®</span> recoge una guía de salud para fomentar hábitos de vida correctos sin llegar a la obsesión. Si eres de lo que más le cuesta deshacerse de malos hábitos, encontrarás varios caminos para poder reeducarlos y adaptarlos a tu ritmo. Con mi método podrás comer mejor y sabrás tomar mejores decisiones incluso si caes enfermo. La dietoterapia es crucial en momentos críticos como la enfermedad y la recuperación. Aunque este portal no sustituye a ningún profesional.
            </div>
            <div className="md:px-4 py-4">
            🛒  Aquí encontrarás <span className="circularbook">guías de compra, consejos de salud, diseño de platos y menús equilibrados</span> para que no te quedes sin ideas qué cocinar. Recuerda que la alimentación es un proceso voluntario y por tanto educable. Los hábitos de vida son los que marcan nuestra salud y calidad de vida. Constancia y compromiso son la base del éxito. Somos responsables de nuestra salud y de <span className="circularbook">enseñar a nuestros hijos a comer mejor</span>. Es la mejor herencia.
            </div>
            <div className="md:px-4 py-4">
            🔊 <span className="circularbook">Únete al CLUB COCINA SANA</span> con un programa formativo muy especial, sesiones en directo, recetas saludables y muchas sorpresas preparadas para tí. Por eso, no te puedes perder ninguna historia en mis redes sociales e inscribirte a  mi newsletter y mi canal de telegram. ¡Estáte muy atenta!
            </div>
        </div>

        <div className="pb-4 text-center">
              <img
                  alt="dietista colegiado"
                  src="https://recetascocinasana.s3.eu-west-1.amazonaws.com/1343_1_a9cf6ab9_28caf96ebd.png"
                  className="w-44 mx-auto"
              />  
        </div> 

        <Newsletter />
    </Layout>
  )
}